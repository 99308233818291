<template>
  <div id="app">
    <!-- <router-link :to="{name:'highTeacher\n',query:{time:new Date().getTime()}}">跳转页面</router-link> -->
    <router-view> </router-view>
  </div>
</template>
<script>
export default {
   

  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
  mounted() {
    if (this.$isMobile()) {
      //这里是移动端
    } else {
      //这里是pc端
        //  window.location.href = "https://www.winlandedu.com/page.html";
    }

  },

};
</script>

<style lang="less">
* {
  margin: 0;
  padding: 0;
  font-family: PingFangSC-Regular;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  -webkit-overflow-scrolling: touch !important;
}

</style>
