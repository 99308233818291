import Vue from "vue";
import VueRouter from "vue-router";
// import Home from "../views/home/index.vue";

Vue.use(VueRouter);

const routes = [
  { path: "/", redirect: "/home" },
  {
    path: "/home",
    name: "home",
    component: () => import("../views/home"),
    meta: {
      title: "文蓝艺术",
    },
    // children:[
    //   { path: "/", redirect: "/c" },
    //   {
       
    //     path:'/c',
    //     name:'c',
    //     component: () => import("../views/children"),
    //     children:[
         
    //       {
           
    //         path:'/',
    //         name:'d',
    //         component: () => import("../views/son"),
    //       }
    //     ]
    //   }
    // ]
  },
  {
    path: "/stuplan",
    name: "stuplan",
    component: () => import("../views/studyAbroadPlan"),
    meta: {
      title: "文蓝艺术-留学规划",
    },
  },
  {
    path: "/topschool",
    name: "topschool",
    component: () => import("../views/topSchool"),
    meta: {
      title: "文蓝艺术-顶尖院校",
    },
  },
  {
    path: "/wlStudy",
    name: "wlStudy",
    component: () => import("../views/wlStudy"),
    meta: {
      title: "文蓝艺术-学在文蓝",
    },
  },
  {
    path: "/jobPlan",
    name: "jobPlan",
    component: () => import("../views/jobPlan"),
    meta: {
      title: "文蓝艺术-求职规划",
    },
  },
  {
    path: "/m50",
    name: "m50",
    component: () => import("../views/m50"),
    meta: {
      title: "文蓝艺术-专业介绍",
    },
  },

  {
    path: "/highTeacher",
    name: "highTeacher",
    component: () => import("../views/highTeacher"),
    meta: {
      title: "文蓝艺术-优质师资",
    },
  },
  {
    path: "/universityInfo",
    name: "universityInfo",
    component: () => import("../views/universityInfo"),
    meta: {
      title: "文蓝艺术-大学详情页面",
    },
  },
  {
    path: "/articlelist",
    name: "articlelist",
    component: () => import("../views/articlelist"),
    meta: {
      title: "文蓝艺术-详情列表",
    },
  },
  {
    path: "/articleInfo",
    name: "articleInfo",
    component: () => import("../views/articleInfo"),
    meta: {
      title: "文蓝艺术-详情页面",
    },
  },

  // 落地页  雅思专区
  {
    path: "/ys",
    name: "ys",
    component: () => import("../views/yszq/index.vue"),
    meta: {
      title: "雅思专区",
    },
  },
  // 落地页 雅思多邻国
  {
    path: "/ysgre",
    name: "ysgre",
    component: 1,
    component: () => import("../views/ysGre"),
    meta: {
      title: "多邻国",
    },
  },
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
 
  // chrome
   
  document.body.scrollTop = 0
   
  // firefox
   
  document.documentElement.scrollTop = 0
   
  // safari
   
  window.pageYOffset = 0
   
  next()
   
  })
  
  

export default router;
