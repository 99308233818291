import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// import 'swiper/css/swiper.css'
// Vue.component(Carousel.name,Carousel)
// import Carousel from '@/components/Carousel'
// import 'swiper/dist/css/swiper.css'
// import VueAwesomeSwiper from 'vue-awesome-swiper'
// Vue.use(VueAwesomeSwiper)
import Swiper from 'swiper'
import "swiper/css/swiper.css"
// Vue.use(Swiper)


import axios from 'axios'
Vue.prototype.$http = axios

Vue.config.productionTip = false

// 引入vant
import Vant from 'vant';
import 'vant/lib/index.css';
import 'vant/lib/icon/local.css';
Vue.use(Vant);
//引入视频
// import VideoPlayer from 'vue-video-player'
// require('video.js/dist/video-js.css')
// require('vue-video-player/src/custom-theme.css')
// Vue.use(VideoPlayer)
import isMobile from './utils/isMobile'
Vue.prototype.$isMobile = isMobile 



router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

let vueMain = new Vue({
  router,
  store,
  Swiper,
  render: h => h(App),
//添加到这里,这里的render-event和vue.config.js里面的renderAfterDocumentEvent配置名称一致
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  }

}).$mount('#app')


export default vueMain
